import React, { useEffect } from "react"
import photo2 from "../assets/site photo 3.jpg"
import thomasNews from "../assets/thomasNews.jpg"
import styles from "./About.module.css"
import AOS from "aos"
import { Link } from "react-router-dom"
import styles2 from "../home/Home.module.css"

const AboutMe = () => {
    useEffect(() => {
        AOS.init()
      }, [])
    
      return (
        <>
          <header className={styles.mainHeader}>
            <h1>
              <span>About </span>Thomas
            </h1>
            <p> My gift is to inspire others, and make dreams come true! </p>
          </header>
    
          <div className={styles.container}>
            <section className={styles.card}>
              <img className={styles.image} src={thomasNews} alt="My Story" />
    
              <div className={styles.description}>
                <h3>My Story</h3>
                <p> During 2020 the year of the pandemic, I decided to make a career change. I enrolled in a boot camp for cybersecurity. During my tenure I received my net + and sec + certs and resigned from my Higher Education role, and accepted my first job opportunity in tech. During my transition I came along many opportunities to help promote myself and others in the tech journey. I was able to tell my story to many aspiring individuals looking to make a career change or get started immediately from HS or college into the industry. I was given opportunities to speak about my story in televised commercials, speak at community events, have visibility on billboards across the state, be listed in diversity and inclusion trainings, and mentor adults in many areas. I'd like to further share my story and create a website and brand where I can help those seeking to change careers like I did. </p>
              </div>
            </section>
    
            <section className={styles.card}>
              <img className={styles.image} src={photo2} alt="My Mission" />
              <div className={styles.description}>
                <h3 data-aos="zoom-in-right">My Mission</h3>
                <p data-aos="fade-up">My mission as a mentor in tech is to cultivate a community of lifelong learners who are passionate about technology and committed to personal  and professional growth. By sharing my knowledge and experiences, I aim to inspire others to pursue their goals and achieve success in the tech industry. I strive to create a supportive and inclusive environment that fosters creativity, innovation, and collaboration, where individuals are empowered to take risks and learn from their mistakes. Ultimately, my goal is to empower aspiring tech professional to achieve their full potential and make a positive impact on the world.</p>
              </div>
            </section>
          </div>
          <div className={styles.form}>
            <Link to="/contact" href="#top"
            >
                    <button
              className={styles2.button}
              data-aos="fade-up"
              style={{
                maxWidth: "120px",
                padding: "5.5px",
              }}
              onClick="scroll(0, 0)"
            >
              Connect with Thomas
            </button>
    
            </Link>
          </div>
        </>
      )
    }

export default AboutMe
