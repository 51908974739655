import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Col, Container, Row } from 'reactstrap';


function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_uutiqhu', 'template_5beeqnj', form.current, "ytteOa2UQs1qA66TT")
        .then((result) => {
            console.log(result.text);
            alert(`Thank you for reaching out! Thomas will be in touch soon.`)
            form.current.reset(); 
        }, (error) => {
            console.log(error.text);
            alert(`Sorry, contact failed! ${error.text}`)
        });
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", height: "85vh" }}
        >
            <Container>
                <Row>
                <h1 style={{ color: "white", fontFamily: "Georgia, serif" }}>
                <span style={{ color: "#ffcd51" }}>Contact</span> Thomas
                </h1>
                <Col                 
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "20px",
                    color: "white",
                }} >
                    {/* <Form onSubmit={sendEmail} ref={form}>
                        <FormGroup style={{width: "45vw"}}>
                            <Label>Name or Name of Organization</Label>
                            <Input type='text' name='user_name' /> 
                        </FormGroup>
                        <FormGroup style={{width: "45vw"}}>
                            <Label>Email</Label>
                            <Input type='text' name='user_email' /> 
                        </FormGroup>
                        <FormGroup style={{width: "45vw"}}>
                            <Label>Message</Label>
                            <Input type='textarea' name='message' /> 
                        </FormGroup>
                        <Button type='submit' value="send" >Send Message</Button>
                    </Form> */}
                    <form ref={form} onSubmit={sendEmail} style={{width: "45vw", display: "flex", flexDirection: "column"}}>
                        <label style={{padding: "1vh"}}>Name or Business</label>
                        <input type="text" name="from_name" required="true" />
                        <label style={{padding: "1vh"}}>Email</label>
                        <input type="email" name="from_email" required="true" />
                        <label style={{padding: "2vh"}}>Message</label>
                        <textarea name="message" style={{height: "15vh", padding: "1vh"}} required="true"/>
                        <br/> 
                        <button style={{padding: "1vh"}}type="submit" value="Send">Send Message</button>
                    </form>
                </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Contact