import React, { useEffect, useRef } from "react"
import styles from "./Home.module.css"
import TestimonialCarousel from "../carousel/Carousel"
import aboutImage from "../assets/site_photo_6.jpg"
import { BsTelephone } from "react-icons/bs"
import { AiOutlineMail } from "react-icons/ai"
import { Link } from "react-router-dom"
import AOS from "aos"
import {
  Container,
  Row,
  Col,
} from "reactstrap"
import newImage from "../assets/site_photo_2.jpg"
import emailjs from '@emailjs/browser';

function Home() {
    useEffect(() => {
        // scrolling effect
        AOS.init()
      }, [])
    
      const form = useRef();

      const sendEmail = (e) => {
      e.preventDefault();
  
      emailjs.sendForm('', 'template_5beeqnj', form.current, "ytteOa2UQs1qA66TT")
          .then((result) => {
              console.log(result.text);
              alert(`Thank you for reaching out! Thomas will be in touch soon.`)
              form.current.reset(); 
          }, (error) => {
              console.log(error.text);
              alert(`Sorry, contact failed!`)
          });
      };
  
      //Header Ideas
      //Create A Plan, Change Careers, Transform Your Life!
      return (
        <>
          <main className={styles.main}>
            <img className={styles.mainPhoto} src={newImage} alt="mainPhoto" />
          </main>
          <div className={styles.container}>
            <section className={styles.card}>
              <img
                id="about-image"
                className="img-fluid"
                src={aboutImage}
                alt="My Story"
              />
              <div className={styles.description}>
                <h3 className={styles.titles}>About Thomas Edwards</h3>
                <p className={styles.paragraph} data-aos="fade-up">
                During 2020 the year of the pandemic, I decided to make a career change. I enrolled in a boot camp for cybersecurity. During my tenure I received my net + and sec + certs and resigned from my Higher Education role, and accepted my first job opportunity in tech. During my transition I came along many opportunities to help promote myself and others in the tech journey. I was able to tell my story to many aspiring individuals looking to make a career change or get started immediately from HS or college into the industry. I was given opportunities to speak about my story in televised commercials, speak at community events, have visibility on billboards across the state, be listed in diversity and inclusion trainings, and mentor adults in many areas. I would like to further share my story, and help those wanting to change careers like I did.
                </p>
                <Link to="/about" href="#">
                  <button
                    className={styles.button}
                    style={{ maxWidth: "120px", padding: "5.5px" }}
                    onClick="scroll(0, 0)"
                  >
                    Read My Story
                  </button>
                </Link>
              </div>
            </section>
          </div>
    
          {/* carousel component */}
          <div
            class="container-fluid py-2"
            style={{ alignItems: "center", justifyContent: "center" }}
          >
            <TestimonialCarousel />
          </div>
    
          {/* How can I help section */}
          <h1 className={styles.titles}>Work with Thomas</h1>
          <br />
          <Container>
            <Row>
              <Col>
              <div data-aos="fade-up">

              <AiOutlineMail style={{ color: "white" }}  size={80} />
              <br />
              <Link to="/contact" >
                    <button
                      className={styles.button}
                      style={{
                        maxWidth: "120px",
                        padding: "5.5px",
                        marginTop: "15px",
                      }}
                      onClick="scroll(0, 0)"
                    >
                      Learn More
                    </button>
                  </Link>
                  </div>
              </Col>

              <Col style={{ paddingBottom: "3em"}}>
                <div data-aos="fade-up">
                  <BsTelephone style={{ color: "white" }} size={80} />
                  <br />
                  <Link
                    to="https://calendly.com/innerfamous"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button
                      className={styles.button}
                      style={{
                        maxWidth: "120px",
                        padding: "5.5px",
                        marginTop: "15px",
                      }}
                      
                    >
                      Schedule A Meeting
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </>
      )
    
}

export default Home