import './App.css';
import Navigation from "./components/navigation/Navigation";
import About from "./components/about/About";
import Home from "./components/home/Home";
import Footer from "./components/footer/Footer";
import Testimonials from "./components/testimonials/Testimonials"; 
import Contact from './components/contact/Contact';
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Navigation />
      <BrowserRouter>
        <Routes>
          <Route path='/testimonials' element={<Testimonials />} /> 
          <Route path='/about' element={<About />} /> 
          <Route path='/' element={<Home />} /> 
          <Route path='/contact' element={<Contact />} /> 
        </Routes>
      </BrowserRouter>
      <Footer /> 
    </div>
  );
}

export default App;
